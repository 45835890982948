import { FormlyStuff } from '@agdir/agdir-forms';
import { AuthService } from '@agdir/domain';
import { CUSTOMER_PATHS } from '@/constants';
import { Component, inject, Injectable, OnDestroy, OnInit, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router, Routes } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AuthCandidateSession, SmsError } from '@/auth';

@Injectable({ providedIn: 'root' })
export class ViewService {
	readonly authCandidate$ = new BehaviorSubject<AuthCandidateSession>({
		phone: '',
	});
	readonly viewTitle = signal<string>('');
	readonly viewSubtitle = signal<string>('');
	readonly viewNextLabel = signal<string>('');
	readonly viewPreviousLabel = signal<string>('');
	readonly customerFlow = new BehaviorSubject<Routes>([]);
	readonly currentFormlyStuff = signal<FormlyStuff<any> | null>(null);
	currentStepComponent = signal<ViewBaseComponent | null>(null);
	progress$ = signal<boolean>(false);
	error$ = signal<SmsError | null>(null);
	private readonly router = inject(Router);
	private readonly route = inject(ActivatedRoute);
	/**
	 * This is injected in root so the activated route is the base route, not the Quick Component route
	 */
	readonly currentQuickFlowComponentRoute$ = new BehaviorSubject<ActivatedRoute>(this.route);

	async goToNextStep(nextStepIncrement = 1) {
		const nextStep = this.getNextStep(nextStepIncrement);
		if (!!nextStep) {
			await this.router.navigate([nextStep.path], {
				relativeTo: this.currentQuickFlowComponentRoute$.value.parent,
				// skipLocationChange: true,
			});
		} else {
			await this.gotToShell();
		}
	}

	async goToPreviousStep() {
		const previousStep = this.getPreviousStep();
		if (!!previousStep) {
			await this.router.navigate([previousStep.path], {
				relativeTo: this.currentQuickFlowComponentRoute$.value.parent,
				// skipLocationChange: true,
			});
		}
	}

	getNextStep(nextStepIncrement = 1): Route | null {
		const currentComponentInRoutes = this.customerFlow.value.find((route) => route.component === this.currentStepComponent()?.constructor);
		if (currentComponentInRoutes) {
			const currentRouteIndexInFlow = this.customerFlow.value.indexOf(currentComponentInRoutes);
			return currentRouteIndexInFlow < this.customerFlow.value.length + nextStepIncrement
				? this.customerFlow.value[currentRouteIndexInFlow + nextStepIncrement] || null
				: null;
		}
		return null;
	}

	getPreviousStep(): Route | null {
		const currentComponentInRoutes = this.customerFlow.value.find((route) => route.component === this.currentStepComponent()?.constructor);
		if (currentComponentInRoutes) {
			const currentRouteIndexInFlow = this.customerFlow.value.indexOf(currentComponentInRoutes);
			return currentRouteIndexInFlow > 0 ? this.customerFlow.value[currentRouteIndexInFlow - 1] || null : null;
		}
		return null;
	}

	hasNextStep(nextStepIncrement = 1): boolean {
		return this.getNextStep(nextStepIncrement) !== null;
	}

	hasPreviousStep(): boolean {
		return this.getPreviousStep() !== null;
	}

	registerCurrentStepComponent(cmp: ViewBaseComponent) {
		this.currentStepComponent.set(cmp);
	}

	async submitStep(e?: Event) {
		this.progress$.set(true);
		const canProceed = await this.currentStepComponent()?.submitStep(e);
		this.progress$.set(false);
		if (canProceed) {
			await this.goToNextStep();
		}
	}

	private gotToShell() {
		return this.router.navigateByUrl(CUSTOMER_PATHS.ALL_FARMS);
	}
}

@Component({
	standalone: true,
	selector: 'agdir-quick-page-base',
	template: ``,
})
export abstract class ViewBaseComponent<T = any> implements OnInit, OnDestroy {
	destroyed$ = new Subject<void>();
	protected formlyStuff = signal<FormlyStuff<T> | null>(null);
	protected route = inject(ActivatedRoute);
	protected router = inject(Router);
	protected service = inject(ViewService);
	protected auth = inject(AuthService);
	private title = inject(Title);
	private translocoService = inject(TranslocoService);

	ngOnDestroy(): void {
		this.service.currentFormlyStuff.set(null);
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	ngOnInit() {
		this.service.viewTitle.set(this.route.snapshot.data['title']);
		if (this.service.viewTitle()) {
			const titleWihtoutFirstPart = this.service.viewTitle().split('.').slice(1).join('.');
			this.translocoService
				.selectTranslate(titleWihtoutFirstPart, undefined, { scope: 'quick-auth' })
				.pipe(takeUntil(this.destroyed$))
				.subscribe((title) => this.title.setTitle(title));
		}
		this.service.viewSubtitle.set(this.route.snapshot.data['subtitle']);
		this.service.viewNextLabel.set(this.route.snapshot.data['nextLabel']);
		this.service.viewPreviousLabel.set(this.route.snapshot.data['previousLabel']);
		this.service.registerCurrentStepComponent(this);
		this.service.currentFormlyStuff.set(this.formlyStuff() || null);
	}

	/**
	 * Do not call this method directly, use the service.submitStep instead
	 * @param event
	 */
	abstract submitStep(event?: Event): Promise<boolean>;
}
